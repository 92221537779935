﻿import { IIconResult } from "../utils/icons";
import {
	getParentWithClassName,
	hide,
	isMobileOnPageWithHeader,
	expressQuerySelector,
	expressQuerySelectorAll
} from "../common/html";
import { getParameter } from "../utils/querystring";
import { getHtmlRequest, getRequest, postRequest } from "../utils/fetch";
import { initNavigationDesktop } from "./navigation-desktop";
import { initLanguageSelector } from "./language-selector";
import { initSearchBar } from "./search-bar";
import { createNotificationCenter } from "./notification-center";
import { createClickableElement } from "./button";
import { initMyAccount } from "./my-account";
import { initCountDown } from "./countdown";
import { initNavigationMobile } from "./navigation-mobile";
import {getCookie, writeCookieThatExpiresAtTheEndOfTheDay} from "../utils/cookie";

export interface IMainNavigationDependencies {
	readonly getInlineSvgsAsync: (ids: string[]) => Promise<IIconResult[]>;
	readonly openLeadTimesPopupAsync?: () => Promise<void>;
}
interface IEventAnimation {
	toggleEventVisibility: (open: boolean) => void;
}

export function createMainNavigation(containerEl: HTMLElement, deps: IMainNavigationDependencies) {
	const mainNavigationEl = containerEl;
	const mobileMenuEl = expressQuerySelector<HTMLElement>(mainNavigationEl, "#technical-mobile-menu", false);
	const isMobile = () => isMobileOnPageWithHeader();

	const initEventAnimation = (): IEventAnimation => {
		const eventContainer = expressQuerySelector<HTMLElement>(containerEl, '.technical-event-container', false);
		if (!eventContainer || !window['lottie']) return {
			toggleEventVisibility: () => { return; }
		};
		const seen = getCookie('evSeen') && getCookie('evSeen') === '1';
		const imageContainer = expressQuerySelector<HTMLElement>(containerEl, '.technical-animate-img', true);
		const animation = lottie.loadAnimation({
			container: imageContainer,
			renderer: 'svg', // Choose the renderer
			loop: false,
			autoplay: false,
			path: window.context.cdn + imageContainer.dataset.catimage,
			initialSegment: seen ? [255, 354] : [0, 354]
		});

		animation.addEventListener('complete', () => {
			// Loop the last 4 seconds of the animation continuously
			animation.playSegments([255, 354], true);
		});

		const animate = expressQuerySelector<HTMLElement>(containerEl, `.technical-animate-img`, true);
		animate.style.transition = "opacity 0.5s";
		animate.style.opacity = "1";

		const eventContainerInitialHeight = eventContainer.clientHeight;
		const toggleEventVisibility = (visiblePx: number) => {
			if (!isMobile()) {
				imageContainer.style.opacity = `${1 - ((100 / 70) * visiblePx) / 100}`;
				return;
			}
			eventContainer.style.opacity = `${1 - ((100 / 70) * visiblePx) / 100}`; // Changes the opacity on scroll
			eventContainer.style.height = `${eventContainerInitialHeight - ((100 / 70) * visiblePx) / 5}px`; // Slightly collapses the evenContainer for a nice effect
		};

		if (eventContainer.dataset.stickynav === 'true')
			document.addEventListener('scroll', _ => !getScrollingElement().classList.contains('u-no-scroll-mobile') && toggleEventVisibility(isMobile() ? window.scrollY > eventContainerInitialHeight ? eventContainerInitialHeight : window.scrollY : 0));

		animation.play();
		if (!seen) writeCookieThatExpiresAtTheEndOfTheDay('evSeen', '1');

		return {
			toggleEventVisibility: (hide: boolean) => toggleEventVisibility(hide ? eventContainerInitialHeight : 0)
		};
	};

	const initComponents = () => {
		const eventAnimation = initEventAnimation();

		// Mobile Menu
		const onOpen = () => { eventAnimation.toggleEventVisibility(true); };
		const onClose = () => { eventAnimation.toggleEventVisibility(false); };
		initMobileMenu(onOpen, onClose);
		const loadDesktopMenuItemAsync = (firstLevel = -1, secondLevel = -1) => {
			if (secondLevel < 0)
				return getHtmlRequest("/apicore/header/desktopmenu?indexLevel1=" + firstLevel).then(res => res as string);
			else
				return getHtmlRequest("/apicore/header/desktopmenu?indexLevel1=" + firstLevel + "&indexLevel2=" + secondLevel).then(res => res as string);
		};
		// Desktop Menu
		const desktopLeftMenuEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.technical-desktop-left-menu', false);
		if (desktopLeftMenuEl) {
			initNavigationDesktop(
				desktopLeftMenuEl, { loadDesktopMenuItemAsync }
			);
		}

		// Language Selector
		const langButtonEl = expressQuerySelector<HTMLElement>(mainNavigationEl, ".technical-language-selector", false);
		const langContainerEl = expressQuerySelector<HTMLElement>(mainNavigationEl, ".technical-language-selector-choices", false);
		if (langButtonEl && langContainerEl) {
			initLanguageSelector(langButtonEl, langContainerEl);
		}

		const searchBarEl = expressQuerySelector<HTMLElement>(mainNavigationEl, ".o-main-navigation__searchbar", false);
		if (searchBarEl) {
			initSearchBar(
				searchBarEl,
				expressQuerySelector<HTMLElement>(mainNavigationEl, ".o-icon-navigation__search-bar", true),
				(searchTerm: string) => getRequest<string[]>("/apicore/searchapi/autocomplete?searchterm=" + searchTerm).then((res: string[]) => res),
				() => { !isMobile() && eventAnimation.toggleEventVisibility(true); },
				() => { eventAnimation.toggleEventVisibility(false); }
			);
		}

		// Notification Center
		const notificationCenterEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.o-icon-navigation__notifications-center', false);
		if (notificationCenterEl) {
			createNotificationCenter(
				notificationCenterEl,
				{
					getNotifications: () => getHtmlRequest("/apicore/Header/LoadNotificationTiles", {
						headers: new Headers({
							'pragma': 'no-cache',
							'cache-control': 'no-cache'
						})
					})
						.then(res => res as string),
					isMobile: isMobile,
					markNotificationsAsRead: (notificationIds: string[]) => {
						const data = new URLSearchParams();
						notificationIds.forEach(el => {
							data.append('notificationIds', el);
						});

						return postRequest<FormData, boolean>("/apicore/Header/MarkNotificationAsRead", data, { headers: { "Content-Type": "application/x-www-form-urlencoded" } }, true)
							.then((res: boolean) => res);
					},
					onOpen: () => { isMobile() && eventAnimation.toggleEventVisibility(true); toggleScroll(); },
					onClose: () => { eventAnimation.toggleEventVisibility(false); toggleScroll(); },
				},
			);
		}

		// My-Account
		const myAccountMenuEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.o-icon-navigation__user-menu', false);
		if (myAccountMenuEl) {
			initMyAccount(
				mainNavigationEl,
				{
					getIconsAsync: deps.getInlineSvgsAsync,
					isMobile: isMobile,
					onOpen: () => { isMobile() && eventAnimation.toggleEventVisibility(true); toggleScroll(); },
					onClose: () => { eventAnimation.toggleEventVisibility(false); toggleScroll(); } ,
					loadAccountMenu: () => getHtmlRequest("/apicore/Header/LoadMyAccount").then((res: string) => res)
				}
			);
		}

		const leadTimesBarEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.technical-leadtimes', false);
		if (leadTimesBarEl) {
			createClickableElement(
				leadTimesBarEl,
				deps.openLeadTimesPopupAsync
			);

			getParameter('leadtimes') === 'true' && leadTimesBarEl.click();
		}

		// init countdowns on value propositions
		const topBarContainerEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.technical-top-bar-container', false);
		if (topBarContainerEl) {
			const countDownEls = expressQuerySelectorAll<HTMLElement>(topBarContainerEl, ".technical-countdown");
			countDownEls.forEach(el => {
				const countDownToDate = el.dataset.countdown || "";
				const daysLabel = el.dataset.dayslabel || "";
				const dayLabel = el.dataset.daylabel || "";
				if (countDownToDate !== "") {
					const onCountDownFinish = () => {
						const barEls = expressQuerySelectorAll(topBarContainerEl, ".technical-top-bar");
						if (barEls.length === 1) {
							// Hide container
							hide(topBarContainerEl);
						} else {
							// delete own top bar
							const myTopBar = getParentWithClassName(el, "technical-top-bar");
							myTopBar && myTopBar.remove();

							// switch container to single
							topBarContainerEl.classList.remove("m-top-bar--combo");
							topBarContainerEl.classList.add("m-top-bar--single");

							// remove div around other top bar
							const otherTopBar = barEls.filter(el => el !== myTopBar)[0];
							if (otherTopBar)
								otherTopBar.outerHTML = otherTopBar.innerHTML;
						}
					};

					initCountDown(topBarContainerEl, el, countDownToDate, {
						showDays: true,
						onCountDownFinish: onCountDownFinish,
						daysLabel,
						dayLabel
					});
				}
			});
		}
	};

	const initMobileMenu = (onOpen: () => void, onClose: () => void) => {
		if (mobileMenuEl) {
			const path = '/apicore/header/mobilemenu';
			const pageid = mobileMenuEl.getAttribute("data-page-id");
			initNavigationMobile(
				{
					mobileMenuEl: mobileMenuEl,
					containerEl: expressQuerySelector(document, ".technical-mobile-navigation", true),
					menuButtonEl: expressQuerySelector(document, ".technical-mobile-navigation-hamburger-menu-button", true),
					menuContainerEl: expressQuerySelector(document, ".technical-mobile-navigation-hamburger-menu-container", true),
					currentLanguage: mobileMenuEl.getAttribute("data-current-language") || undefined
				}, {
				getMobileMenu: (indexLevel2: number, indexLevel3: number) =>
				getHtmlRequest(path + `?indexLevel2=${indexLevel2 ?? -1}&indexLevel3=${indexLevel3 ?? -1}&pageId=${pageid}`, { headers: new Headers({ 'pragma': 'no-cache', 'cache-control': 'no-cache' }) }).then(res => res as string),
				getIconsAsync: deps.getInlineSvgsAsync,
				onClose: () => { onClose(); toggleScroll(); },
				onOpen: () => { onOpen(); toggleScroll(); },
			});
		}
	};

	const toggleScroll = () =>
		!getScrollingElement().classList.contains('u-no-scroll-mobile')
			? getScrollingElement().classList.add('u-no-scroll-mobile')
			: getScrollingElement().classList.remove('u-no-scroll-mobile');

	const getScrollingElement = () => (document.scrollingElement || document.documentElement) as HTMLElement;

	initComponents();
}
